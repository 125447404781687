/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@fortawesome/fontawesome-free/css/all.css';



.slide-up-menu {
  transition: transform 0.3s ease-in-out;
  transform: translateX(300px);

  &.active {
    transform: translateX(0);
  }
}

.nav-link {
  position: relative;
  overflow: hidden; /* Ensures the pseudo-element is contained within the button's bounds */
  /* other styling */
}

.nav-link::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  height: 2px; /* Height of the border */
  transition: all 0.3s ease; /* Adjust timing as needed */
}

.nav-link.active-border::after {
  left: 0;
  right: 0;
}
